import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  Table,
} from 'reactstrap'
import { H3, H4, Image, LI, UL } from '../../../../AbstractElements'
import Chat from '../../../Chat'

const TaskDetails = () => {
  const navigate = useNavigate()
  const [messageInput, setMessageInput] = useState('')

  const handleGoBack = () => {
    navigate(-1)
  }

  const handleMessageChange = (message) => {
    setMessageInput(message)
  }

  return (
    <>
      <Container fluid={true}>
        <Row style={{ marginTop: 30 }}>
          <Col sm="12" md="10" className="offset-md-1">
            <Row style={{ marginTop: 30 }}>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <Nav className="nav-pills">
                      <NavItem>
                        <NavLink
                          href="#open-task"
                          className="active"
                          onClick={handleGoBack}
                        >
                          <i className="fa fa-chevron-left"></i> Back
                        </NavLink>
                      </NavItem>
                    </Nav>

                    <Card className="shadow-none">
                      <CardHeader>
                        <H4>Asset Name (Asset Type)</H4>
                      </CardHeader>
                      <CardBody>
                        <div className="row">
                          <div
                            className="col-md-12"
                            style={{
                              borderBottom: '1px solid white',
                              paddingRight: 50,
                            }}
                          >
                            <Form className="needs-validation" noValidate="">
                              <Row>
                                <Col md="12">
                                  <div className="form-floating mb-3">
                                    <input
                                      className="form-control"
                                      id="validationCustom01"
                                      type="text"
                                    />
                                    <label htmlFor="floatingInput22">
                                      Task Name
                                    </label>
                                  </div>
                                </Col>
                                <Col md="12">
                                  <div className="form-floating mb-3">
                                    <textarea
                                      className="form-control"
                                      style={{ height: 80 }}
                                    ></textarea>
                                    <label htmlFor="floatingInput22">
                                      Task Description
                                    </label>
                                  </div>
                                </Col>
                              </Row>
                            </Form>
                            
                            

                            {/* <UL>
                              <LI
                                attrLI={{
                                  style: { backgroundColor: 'transparent' },
                                  className: `clearfix border-0 active`,
                                }}
                              >
                                <Image
                                  attrImage={{
                                    src: `${require('../../../../assets/images/user/user-plain.png')}`,
                                    className: 'rounded-circle user-image',
                                    alt: '',
                                  }}
                                />
                                <div className={`status-circle online`}></div>
                                <div className="about">
                                  <div className="name">{'item.name'}</div>
                                  <div className="status">{'item.status'}</div>
                                </div>
                              </LI>
                            </UL> */}

                            <div className="shadow-none">
                              <CardHeader
                                className="p-0 mt-3 mb-3"
                                style={{ border: 'none' }}
                              >
                                <H3>{'Checklist'}</H3>
                              </CardHeader>
                              <div className="table-responsive">
                                <Table
                                  hover={true}
                                  className="table-border-horizontal"
                                >
                                  <thead>
                                    <tr>
                                      <th scope="col">Erledigti</th>
                                      <th scope="col">Aufgabe</th>
                                      <th scope="col">Bemerkung</th>
                                      <th scope="col">Zeitpunkt</th>
                                      <th scope="col">Name</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {[...Array(3)].map((item, index) => (
                                      <tr>
                                        <th scope="row">
                                          <div className="checkbox checkbox-primary text-center">
                                            <Input
                                              id="checkbox3"
                                              type="checkbox"
                                            />
                                            <Label
                                              for="checkbox3"
                                              style={{ marginBottom: 20 }}
                                            >
                                              {item}
                                            </Label>
                                          </div>
                                        </th>
                                        <td>
                                          {'Checklist item '}
                                          {index + 1}
                                        </td>
                                        <td>{'Remarks'}</td>
                                        <td>{'04.30.2024'}</td>
                                        <td>{'name.name'}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                              </div>
                            </div>

                            <div className="shadow-none">
                              <CardHeader
                                className="p-0 mt-5 mb-3"
                                style={{ border: 'none' }}
                              >
                                <H3>{'Comments'}</H3>
                              </CardHeader>

                              <Chat />
                            </div>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default TaskDetails
