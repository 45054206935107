import React, { createContext, useContext, useState } from 'react'
import { toast } from 'react-toastify'
import { APIList } from '../../api/APIList'
import AxiosProvider from '../../api/AxiosProvider'
import { useAuth } from '../Auth/AuthProvider'
import axios from 'axios'

// Create a context for authentication
const ProjectContext = createContext()

// Create a provider component
export const ProjectProvider = ({ children }) => {
  //api
  const getAllProjectApi = APIList.get_all_projects.link
  //context
  const { authToken } = useAuth()
  //state
  const [searchText, setsearchText] = useState('')
  const [isTicketSubmitted, setIsTicketSubmitted] = useState(false)
  const [projectList, setProjectList] = useState([])
  const [projectAssetsList, setProjectAssetsList] = useState([])
  const [isProjectAssetsListLoading, setIsProjectAssetsListLoading] = useState(
    false,
  )
  const [groupedAssets, setGroupedAssets] = useState([])

  const backendUrl = process.env.REACT_APP_BACKEND_URL
  const backendPort = process.env.REACT_APP_BACKEND_PORT

  let apiUrl = `${backendUrl}`

  if (backendPort) {
    apiUrl += `:${backendPort}`
  }

  const getAllProjects = async (authToken) => {
    try {
      const response = await AxiosProvider.post(
        getAllProjectApi,
        { Type: 1 },
        {
          headers: {
            Authorization: 'Bearer ' + authToken,
            'x-version': '2.0',
          },
        },
      )
      setProjectList(response.data.result)
    } catch (err) {
      console.log(err)
    }
  }

  const getAllProjectAssets = async (asset_type_id) => {
    setIsProjectAssetsListLoading(true)
    try {
      const response = await axios.post(apiUrl + '/api/all-project-assets', {
        token: authToken,
        asset_type_id: asset_type_id,
      })
      setProjectAssetsList(response.data)
    } catch (error) {
      console.log(error)
    } finally {
      setIsProjectAssetsListLoading(false)
    }
  }

  const getAssetTypes = async (token) => {
    try {
      const response = await axios.get((apiUrl += '/api/asset-types'), {
        headers: {
          token: token,
        },
      })
      setGroupedAssets(response.data)
    } catch (error) {
      console.log(error)
    }
  }

  const submitTicket = async (ticketData) => {
    try {
      if (authToken) {
        const response = await axios.post((apiUrl += '/api/submit-ticket'), {
          token: authToken,
          ticketData: ticketData,
        })
        console.log(response.data)
        if (response.data.success) {
          toast.success('Ticket submitted successfully!', {
            autoClose: 1000,
          })
          setIsTicketSubmitted(true)
        } else {
          toast.error('Something went wrong.', {
            autoClose: 1000,
          })
        }
      }
    } catch (err) {
      console.log(err)
      toast.error('Something went wrong.', {
        autoClose: 1000,
      })
    }
  }

  return (
    <ProjectContext.Provider
      value={{
        searchText, setsearchText,
        isProjectAssetsListLoading,
        projectList,
        getAllProjects,
        groupedAssets,
        getAssetTypes,
        projectAssetsList,
        setProjectAssetsList,
        getAllProjectAssets,
        submitTicket,
        setIsTicketSubmitted,
        isTicketSubmitted,
      }}
    >
      {children}
    </ProjectContext.Provider>
  )
}

// Custom hook to access the authentication context
export const useProject = () => useContext(ProjectContext)
