import React from 'react'
import Routers from './Route'
import AnimationThemeProvider from './_helper/AnimationTheme/AnimationThemeProvider'
import { AuthProvider } from './_helper/Auth/AuthProvider'
import { CommentProvider } from './_helper/Comments/CommentProvider'
import CustomizerProvider from './_helper/Customizer/CustomizerProvider'
import { ProjectProvider } from './_helper/Projects/ProjectProvider'
import { ReportProvider } from './_helper/Reports/ReportProvider'
import { TicketProvider } from './_helper/Ticket/TicketProvider'

const App = () => (
  <div className="App">
    <AuthProvider>
      <CustomizerProvider>
        <AnimationThemeProvider>
          <ProjectProvider>
            <TicketProvider>
              <ReportProvider>
                <CommentProvider>
                  <Routers />
                </CommentProvider>
              </ReportProvider>
            </TicketProvider>
          </ProjectProvider>
        </AnimationThemeProvider>
      </CustomizerProvider>
    </AuthProvider>
  </div>
)

export default App
