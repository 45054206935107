import ConcernPage from '../Components/Pages/Concern'
import HomePage from '../Components/Pages/Home'
import TicketPage from '../Components/Pages/Ticket'
import AssetDetails from '../Components/Pages/Ticket/AssetDetails'
import TaskDetails from '../Components/Pages/Ticket/TaskDetails'

export const routes = [
  { path: `${process.env.PUBLIC_URL}/pages/home`, Component: <HomePage /> },
  {
    path: `${process.env.PUBLIC_URL}/pages/concern/:category/:assetName/:projectId/:assetId/`,
    Component: <ConcernPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/pages/tickets`,
    Component: <TicketPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/pages/asset-details/:id`,
    Component: <AssetDetails />,
  },
  {
    path: `${process.env.PUBLIC_URL}/pages/task-details`,
    Component: <TaskDetails />,
  },
]
