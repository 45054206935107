import { format } from 'date-fns'
import Lottie from 'lottie-react'
import React, { useContext,useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Col, Input, InputGroup, Row } from 'reactstrap'
import { Btn, LI, UL } from '../../AbstractElements'
import { useAuth } from '../../_helper/Auth/AuthProvider'
import { useComment } from '../../_helper/Comments/CommentProvider'
import { useReport } from '../../_helper/Reports/ReportProvider'
import * as sending from '../../assets/images/lottie/sending.json'

const Chat = ({data}) => {
  const { isDark, user } = useAuth()
  const { comments } = useReport()
  const { isSending, addComment } = useComment()
  const [currentDateTime, setCurrentDateTime] = useState('')
  const [messageInput, setMessageInput] = useState('')

  const handleMessageChange = (message) => {
    setMessageInput(message)
  }

  useEffect(() => {
    const getCurrentDateTime = () => {
      const currentDate = new Date()
      const formattedDateTime = format(
        currentDate,
        "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
      )
      setCurrentDateTime(formattedDateTime)
    }
    getCurrentDateTime()
    const interval = setInterval(getCurrentDateTime, 1000) // Update current date and time every second
    return () => clearInterval(interval)
  }, [])

  // const ownerId = comments
  //   .filter((i) => i.createdByLdap === user.username)
  //   .map((item) => {
  //     return item.ownerId
  //   })
  const parentId = comments
    .filter((i) => i.createdByLdap === user.username)
    .map((item) => {
      return item.parentId
    })

  const CommentData = {
    data: {
      ownerId: data?._id, // AssetID
      parentId: data?.chatRoomThema[0]?._id, // Chat ROOM ID
      title: 'Allgemaien',
      text: messageInput,
      createdByLdap: '',
      createdOnDate: currentDateTime,
      changedOnDate: currentDateTime,
      state: 0,
    },
  }

  const submitCommentForm = () => {
    if (messageInput === '') {
      toast.warning('Please type a message...', {
        autoClose: 1000,
      })
    } else {
      setMessageInput('');
      addComment(CommentData);
    }
  }
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      submitCommentForm();
    }
  };
  
  return (
    <div className="chat">
      {comments.length === 0 ? (
        <p className='text-center p-3'>No Comments</p>
      ) : (
        <div
          className="chat-history chat-msg-box custom-scrollbar p-3"
          style={{
            background: isDark?'#333846':'#f5f6fa',
            borderRadius: 5,
            marginBottom: 10,
            height: 250,
            maxHeight: '500px',
            overflowY: 'auto',
          }}
        >
          <UL>
            {comments?.map((item, index) => (
                <LI
                  attrLI={{
                    className: 'clearfix border-0 mb-2',
                  }}
                >
                  <div
                    className="p-3"
                    style={{
                      background: item.createdByLdap === user.username ? '#4688ff' : isDark ? '#262932' : '#dcdcde',
                      color: item.createdByLdap === user.username && isDark ? '#262932' : item.createdByLdap === user.username ? '#fff' : item.createdByLdap !== user.username && isDark ? '#656571' : '',
                      borderRadius: 10,
                      borderBottomRightRadius:
                        item.createdByLdap === user.username ? '0' : '10px',
                      borderBottomLeftRadius:
                        item.createdByLdap !== user.username ? '0' : '10px',
                      minWidth: 300,
                      display: 'inline-block', // Ensures the width adjusts automatically
                      float:
                        item.createdByLdap === user.username ? 'right' : 'left',
                      position: 'relative',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'right',
                        alignItems: 'center',
                      }}
                    >
                      <p style={{ fontSize: 12, fontWeight: '400' }}>
                        {item?.createdByLdap} -{' '}
                        {format(
                          new Date(item?.changedOnDate),
                          'dd.MM.yyyy hh:mm a',
                        )}
                      </p>
                    </div>
                    {item.text}
                  </div>
                </LI>
              ))
            }
          </UL>
        </div>
      )}
      {/* SEND BUTTON */}
      <Row>
        {isSending ? (
          <Col
            xl="12"
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: 30,
            }}
          >
            <div style={{ width: 100, height: 50 }}>
              <Lottie animationData={sending} />
            </div>
          </Col>
        ) : (
          <Col xl="12" className="">
            <InputGroup>
              <Input
                type="text"
                className="form-control input-txt-bx"
                placeholder="Type a message here..."
                value={messageInput}
                required
                onChange={(e) => handleMessageChange(e.target.value)}
                onKeyPress={handleKeyPress}
              />
              <Btn
                attrBtn={{
                  onClick: (e) => submitCommentForm(),
                  color: 'primary',
                }}
              >
                <i class="fa fa-send" style={{ fontSize: 24 }}></i>
              </Btn>
            </InputGroup>
          </Col>
        )}
      </Row>
    </div>
  )
}

export default Chat
