import axios from 'axios'
import React, { createContext, useContext, useState } from 'react'
import { useAuth } from '../Auth/AuthProvider'

// Create a context for authentication
const TicketContext = createContext()

// Create a provider component
export const TicketProvider = ({ children }) => {
  //context
  const { authToken } = useAuth()
  //state
  const [ticketList, setTicketList] = useState([])

  const backendUrl = process.env.REACT_APP_BACKEND_URL
  const backendPort = process.env.REACT_APP_BACKEND_PORT

  let apiUrl = `${backendUrl}`

  if (backendPort) {
    apiUrl += `:${backendPort}`
  }

  const getTicket = async (project_id) => {
    try {
      const response = await axios.post((apiUrl += '/api/get-ticket'), {
        token: authToken,
        project_id: project_id,
      })
      setTicketList(response.data.result)
    } catch (error) {
      console.log(error)
    }
  }


  return (
    <TicketContext.Provider value={{ ticketList, getTicket }}>
      {children}
    </TicketContext.Provider>
  )
}

// Custom hook to access the authentication context
export const useTicket = () => useContext(TicketContext)

// import React, { createContext, useContext, useState } from 'react';
// import { APIList } from '../../api/APIList';
// import AxiosProvider from '../../api/AxiosProvider';
// import { useAuth } from '../Auth/AuthProvider';

// // Create a context for authentication
// const TicketContext = createContext();

// // Create a provider component
// export const TicketProvider = ({ children }) => {
//   //api
//   const getTicketApi = APIList.get_ticket.link;
//   //context
//   const { authToken } = useAuth()
//   //state
//   const [ticketList, setTicketList] = useState([])

//   const getTicket = async (project_id) => {
//     try {
//        const response = await AxiosProvider.post( getTicketApi , { "Id" : project_id, "Type": 5 },
//         {
//           headers: {
//             Authorization: "Bearer " + authToken,
//             'x-version': '2.0',
//           },
//         },
//        );
//        setTicketList(response.data)
//     } catch (err) {
//        console.log(err)
//     }
//  }

//   return (
//     <TicketContext.Provider value={{ ticketList, getTicket }}>
//       {children}
//     </TicketContext.Provider>
//   );
// };

// // Custom hook to access the authentication context
// export const useTicket = () => useContext(TicketContext);
