import moment from 'moment';
import { format } from 'date-fns'
import React, { Fragment, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, CardBody, CardFooter, CardHeader, Col, Container, Form, Row } from 'reactstrap';
import { Btn, H4 } from '../../../AbstractElements';
import { useProject } from '../../../_helper/Projects/ProjectProvider';

const ConcernPage = () => {
  const { t } = useTranslation()
  const history = useNavigate();
  const { projectId : projectId , assetId: assetId, category: category, assetName: assetName } = useParams();
  const { projectAssetsList, getAllProjectAssets, submitTicket, isTicketSubmitted, setIsTicketSubmitted, groupedAssets } = useProject()
  const [currentDateTime, setCurrentDateTime] = useState('')

  useEffect(() => {
    const getCurrentDateTime = () => {
      const currentDate = new Date()
      const formattedDateTime = format(
        currentDate,
        "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
      )
      setCurrentDateTime(formattedDateTime)
    }
    getCurrentDateTime()
    const interval = setInterval(getCurrentDateTime, 1000) // Update current date and time every second
    return () => clearInterval(interval)
  }, [])

  let assetFormList;
    if (projectAssetsList.length === 0) {
      getAllProjectAssets(assetId)
    } else {
      const projectAssetFormList = projectAssetsList?.filter((i)=>i._id === assetId).map((item)=>{ return (item)})
      assetFormList = projectAssetFormList[0];
    }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const submitTicketForm = async (data) => {
    const today = new Date(); // Get the current date
    const formattedDate = moment(today).format('YYYY-MM-DDTHH:mm:ss[Z]');

    // Construct an array of form field objects
    const propertiesData = assetFormList?.properties.map((form, index) => {
      if (form.mode === 1) {
        return {
          propertyTypeEntryId: form._id,
          typeId: assetId,
          valueDateTime: formattedDate,
          valueBool: data[`Name${index}`]
        };
      } else if (form.mode === 2) {
        return {
          propertyTypeEntryId: form._id,
          typeId: assetId,
          valueDateTime: formattedDate,
          valueInt: data[`Name${index}`]
        };
      } else if (form.mode === 3) {
        return {
          propertyTypeEntryId: form._id,
          typeId: assetId,
          valueDateTime: formattedDate,
        };
      } else if (form.mode === 4) {
        return {
          propertyTypeEntryId: form._id,
          typeId: assetId,
          valueDateTime: formattedDate,
          valueString: data[`Name${index}`]
        };
      } else if (form.mode === 5) {
        return {
          propertyTypeEntryId: form._id,
          typeId: assetId,
          valueDateTime: formattedDate,
          valueDecimal: data[`Name${index}`]
        };
      }
      else if (form.mode === 7) {
        return {
          propertyTypeEntryId: form._id,
          typeId: assetId,
          valueDateTime: formattedDate,
          valueString: data[`Name${index}`]
        };
      }
      else {
        return {
          propertyTypeEntryId: form._id,
          typeId: assetId,
          valueDateTime: formattedDate,
          valueTimeStamp: data[`Name${index}`]
        };
      }
    });

    const combineData = { 
      data : {
        chatRoomThema: [
          {
            "title": "Allgemaien",
            "description": "",
            "createdOnDate": currentDateTime,
            "changedOnDate": currentDateTime
          }
        ], 
        parentId: projectId, 
        project_id: projectId, 
        typeId: assetId, 
        name: data.subject, 
        description: data.description, 
        properties: propertiesData,
      }
      
    };
    //console.log(combineData)
    submitTicket(combineData);
  };

  if(isTicketSubmitted){
    setTimeout(()=>{
      setIsTicketSubmitted(false)
      history(`${process.env.PUBLIC_URL}/pages/home`)
    },2000)
  }

  const customizeEntries = assetFormList?.customizeEntries || [];
  const assetProperty = assetFormList?.properties.map((properties) => {
    const options = { option: customizeEntries.filter(customizeEntry => properties.customizeType === customizeEntry._id) };
    return { ...properties, options };
  }) || [];

//console.log('assetFormList',assetFormList)
//console.log('groupedAssets',groupedAssets)

  return (
    <Fragment>
      {/* <ToastContainer autoClose={1000}/> */}
      {/* <Breadcrumbs mainTitle="Concern" parent="Pages" title="Concern" /> */}
      <Container fluid={true}>
        <Row>
          <Col sm="12" md="10" className="offset-md-1">
            <Row style={{ marginTop: 30 }}>
              <Col sm="12">
                {
                  projectAssetsList.length === 0 ? 
                  <Card>
                    <CardHeader><Col sm="12"><Skeleton count={1} height={30} width={'30%'} style={{borderRadius:10, marginBottom: 10}}/></Col></CardHeader>
                    <CardBody>
                      <Row>
                        <Col sm="6"><Skeleton count={3} height={58} style={{borderRadius:10, marginBottom: 10}}/></Col>
                        <Col sm="6"><Skeleton count={3} height={58} style={{borderRadius:10, marginBottom: 10}}/></Col>
                        <Col sm="12"><Skeleton count={1} height={80} style={{borderRadius:10, marginBottom: 10}}/></Col>
                      </Row>
                    </CardBody>
                    <CardFooter><Skeleton count={1} height={35} width={'8%'} style={{borderRadius:10}}/></CardFooter>
                  </Card>
                  : <Card>
                    <CardHeader><H4>{category} - {assetName}</H4></CardHeader>
                    <CardBody>
                      <Form className='needs-validation' noValidate='' onSubmit={handleSubmit(submitTicketForm)}>
                        <Row>
                          <Col md='12'>
                            <div className="form-floating mb-3">
                              <input className="form-control" id='validationCustom01' type='text' name={`subject`} {...register('subject', { required: true })} />
                              <label htmlFor="floatingInput22"><span style={{color:'#F07B05'}}>*</span>Subject</label>
                              <span className='d-block' style={{color:'#F07B05'}}>{errors[`subject`] && 'This field is required'}</span>
                            </div>
                          </Col>
                          {
                            assetProperty.map((form,index)=>(
                              <Col sm="6" key={index}>
                                <div className="form-floating mb-3">
                                  {
                                    form.mode === 2 ? <input className="form-control" id='validationCustom01' type='number' name={`Name${index}`} {...register(`Name${index}`, { required: form.mandatory })} /> 
                                    : form.mode === 3 ? <input className="form-control" id='validationCustom01' type='date' name={`Name${index}`} {...register(`Name${index}`, { required: form.mandatory })} /> 
                                    : form.mode === 4 && form.customizeType === "TEXT" ? <input className="form-control" id='validationCustom01' type='text' name={`Name${index}`} {...register(`Name${index}`, { required: form.mandatory })} /> 
                                    : form.mode === 4 && form.customizeType === "TEXT2" ? <textarea className="form-control" style={{ height: "auto", minHeight: 80, maxHeight: "300px" }} name={`Name${index}`} {...register(`Name${index}`, { required: form.mandatory })} 
                                      onInput={(e) => {
                                      e.target.style.height = "auto";
                                      e.target.style.height = (e.target.scrollHeight) + "px";
                                      }}></textarea> 
                                    : form.mode === 7 ? 
                                    <select
                                      className="form-select form-control form-select"
                                      id={`Name${index}`}
                                      name={`Name${index}`}
                                      {...register(`Name${index}`, { required: form.mandatory })}
                                      defaultValue=""
                                  >
                                      <option value=''></option>
                                      {form.options.option[0].predefiniedProperties.map((property, i) => (
                                        <option key={i} value={property.value}>
                                          {property.value}
                                        </option>
                                      ))}
                                  </select> 
                                    : <input className="form-control" id='validationCustom01' type='text' name={`Name${index}`} {...register(`Name${index}`, { required: form.mandatory })} /> 
                                  }
                                  <label htmlFor="floatingInput22">{form.mandatory === true&&<span style={{color:'#F07B05'}}>*</span>}{form.name}</label>
                                  <span className='d-block' style={{color:'#F07B05'}}>{errors[`Name${index}`] && 'This field is required'}</span>
                                </div>
                              </Col>
                            ))
                          }
                          
                          <Col md='12'>
                            <div className="form-floating mb-3">
                              <textarea className="form-control" style={{ height: "auto", minHeight: 80, maxHeight: "300px" }} {...register('description')} 
                                onInput={(e) => {
                                  e.target.style.height = "auto";
                                  e.target.style.height = (e.target.scrollHeight) + "px";
                                }}></textarea>
                              <label htmlFor="floatingInput22">Description</label>
                            </div>
                          </Col>
    
                        </Row>
                        <Btn attrBtn={{ color: 'primary' }} className="text-end" >{'Submit'}</Btn>
                      </Form>
                    </CardBody>
                  </Card>
                }
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}

export default ConcernPage