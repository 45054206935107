import React, { Fragment, useContext } from 'react';
import { Row } from 'reactstrap';
import CustomContext from '../../_helper/Customizer';
import Leftbar from './Leftbar/index';
import RightHeader from './RightHeader/index';

const Header = () => {
  const id = window.location.pathname.split('/').pop();
  const { toggleIcon } = useContext(CustomContext);

  return (
    <Fragment>
      <div className={`page-header ${toggleIcon ? 'close_icon' : ''}`}>
        <Row className='header-wrapper m-0' style={{paddingTop: 20, paddingBottom:10}}>
          <Leftbar />
          <RightHeader />
        </Row>
      </div>
    </Fragment>
  );
};

export default Header;
