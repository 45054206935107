import React, { useContext, useState } from 'react';
import { ArrowRight, ArrowLeft } from 'react-feather';
import CustomizerContext from '../../_helper/Customizer';
import SidebarMenuItems from './SidebarMenuItems';
import Searchbar from '../Header/RightHeader/Searchbar';
import { useLocation } from 'react-router-dom';

const SidebarMenu = ({ setMainMenu, props, sidebartoogle, setNavActive, activeClass, width }) => {
  const location = useLocation(); // Get the current location object
  const currentPage = location.pathname;
  const isConcernPage = currentPage.includes("/inpro/pages/concern");

  const { customizer } = useContext(CustomizerContext);
  const wrapper = customizer.settings.sidebar.type;
  const [margin, setMargin] = useState(0);

  const scrollToLeft = () => {
    if (margin >= -width) {
      setMargin(0);
      document.querySelector('.left-arrow').classList.add('d-none');
      document.querySelector('.right-arrow').classList.remove('d-none');
    } else {
      setMargin((margin) => (margin += width));
      document.querySelector('.right-arrow').classList.remove('d-none');
    }
  };

  return (
    <nav className="sidebar-main" id="sidebar-main">
      <div className="left-arrow" onClick={scrollToLeft}>
        <ArrowLeft />
      </div>
      <div
        id="sidebar-menu"
        style={
          wrapper.split(' ').includes('horizontal-wrapper')
            ? { marginLeft: margin + 'px' }
            : { margin: '0px' }
        }
      >
        <ul className="sidebar-links custom-scrollbar">
          <SidebarMenuItems setMainMenu={setMainMenu} props={props} sidebartoogle={sidebartoogle} setNavActive={setNavActive} activeClass={activeClass} />
        </ul>
      </div>
      
      {
        isConcernPage?'':
        <div className="right-arrow mb-10" style={{background:'none'}}>
          <Searchbar />
        </div>
      }
      
    </nav>
  );

};

export default SidebarMenu;