import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Card, CardBody, CardHeader, Table } from 'reactstrap'
import { H3, H6, P } from '../../AbstractElements'
import { useProject } from '../../_helper/Projects/ProjectProvider'

const DashboardCard = ({category}) => {
  const { t } = useTranslation()
  const { searchText, groupedAssets } = useProject()
  const navigate = useNavigate()

  const handleRowClick = (item) => {
    navigate(`${process.env.PUBLIC_URL}/pages/concern/${category}/${item.name}/${item.projectId}/${item.assetTypId}/`)
  }

  const filteredRows = groupedAssets[category].filter((asset) =>
    asset.name.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <Card className="p-10">
      <CardHeader className="pt-3 pb-1">
        <H3 className="m-0">{t(category)}</H3>
      </CardHeader>
      <CardBody
        className="p-0 custom-scrollbar rounded"
        style={{ maxHeight: 300, overflow: 'auto' }}
      >
        <div className="taskadd">
          <div className="table-responsive table-hover p-0">
            <Table hover={true}>
              <thead>
                <tr></tr>
              </thead>
              <tbody>
                {filteredRows.map((item, index) => {
                  return (
                    filteredRows.length === 0 ? <tr key={index} onClick={() => handleRowClick(item)} style={{ cursor: 'pointer' }} >
                    
                    <td style={{ background: 'none' }}>
                      <P>No result.</P>
                    </td>
                  </tr> :
                    <tr key={index} onClick={() => handleRowClick(item)} style={{ cursor: 'pointer' }} >
                      <td style={{ width: '30%', background: 'none' }}>
                        <H6 attrH6={{ className: 'task_title_0' }}>
                          {item.name}
                        </H6>
                      </td>
                      <td style={{ background: 'none' }}>
                        <P>{item.beschreibung}</P>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </div>
        </div>
      </CardBody>
    </Card>
  )
}

export default DashboardCard
