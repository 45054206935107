export const APIList = {
  get_all_projects: {
    name: 'All Projects',
    link: 'api/Project/Get',
    desc: 'Get all projects',
    param: ['Type']
  },

  get_projects_assets: {
    name: 'Project Assets',
    link: 'api/AssetType/Get',
    desc: 'Get all project assets',
    param: ['Id','Type']
  },

  submit_ticket: {
    name: 'Submit Tickets',
    link: 'api/Asset/Add',
    desc: 'Create new tickets',
    param: []
  },

  get_ticket: {
    name: 'Get Tickets',
    link: 'api/Asset/Get',
    desc: 'Get ticket list',
    param: ['Id', 'Type']
  },

}
