import React, { useState } from 'react'
import { LogIn, User } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Image } from '../../../AbstractElements'
import { useAuth } from '../../../_helper/Auth/AuthProvider'
import { useNavigate } from 'react-router-dom'

const MobileUserHeader = () => {
  const { user, logout } = useAuth()
  const { t } = useTranslation()
  const history = useNavigate()
  const [langdropdown, setLangdropdown] = useState(false)
  const [selected, setSelected] = useState(localStorage.getItem('i18nextLng'))

  const toggleUser = (language) => {
    if (language) {
      setLangdropdown(!language)
    } else {
      setLangdropdown(!language)
    }
  }

  const Logout = () => {
    logout()
    localStorage.setItem('login', false)
    localStorage.setItem('authenticated', false)
    history(`${process.env.PUBLIC_URL}/login`)
  }

  return (
    <li className="language-nav d-lg-none d-md-none">
      <div className={`translate_wrapper ${langdropdown ? 'active' : ''}`}>
        <div className="current_lang">
          <div className="lang" onClick={() => toggleUser(langdropdown)}>
            <Image
              attrImage={{
                className: `b-r-10 m-0 img-fluid`,
                src: `${require('../../../assets/images/user/5.jpg')}`,
                alt: '',
                style: { width: '40px', height: '40px' },
              }}
            />
          </div>
        </div>

        <div className={`more_lang ${langdropdown ? 'active' : ''}`}>
          <div className="lang" onClick={() => {}}>
            <User style={{marginRight: 10}}/>
            <span> {t('Account')} </span>
          </div>
          <div className="lang" onClick={() => Logout()}>
            <LogIn style={{marginRight: 10}}/>
            <span> {t('Logout')}</span>
          </div>
        </div>
      </div>
    </li>
  )
}

export default MobileUserHeader
