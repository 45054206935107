import React, { useEffect, useState } from 'react'
import { LogIn, User } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Image, LI, P, UL } from '../../../AbstractElements'
import { useAuth } from '../../../_helper/Auth/AuthProvider'

const UserHeader = () => {
  const { user, logout  } = useAuth()
  const { t } = useTranslation()
  const history = useNavigate()
  const [name, setName] = useState()
  const authenticated = JSON.parse(localStorage.getItem('authenticated'))

  useEffect(() => {
    setName(localStorage.getItem('Name') ? localStorage.getItem('Name') : name)
  }, [])

  const Logout = () => {
    logout()
    localStorage.setItem('login', false)
    localStorage.setItem('authenticated', false)
    history(`${process.env.PUBLIC_URL}/login`)
  }

  const UserMenuRedirect = (redirect) => {
    history(redirect)
  }

  return (
    <li className="profile-nav onhover-dropdown pe-0 py-0 d-none d-md-block">
      <div className="media profile-media">
        <Image
          attrImage={{
            className: `b-r-10 m-0 img-fluid`,
            src: `${
              authenticated
                ? require('../../../assets/images/user/user-plain.png')
                : require('../../../assets/images/user/user-plain.png')
            }`,
            alt: '',
            style: { width: '40px', height: '40px' },
          }}
        />
        <div className="media-body">
          <span>{user?.vorname}</span>
          <P attrPara={{ className: 'mb-0 font-roboto' }}>
            {t('Settings')} <i className="middle fa fa-angle-down"></i>
          </P>
        </div>
      </div>
      <UL
        attrUL={{ className: 'simple-list profile-dropdown onhover-show-div' }}
      >
        <LI
          attrLI={
            {
              //onClick: () => UserMenuRedirect(`${process.env.PUBLIC_URL}/app/users/userProfile/${layoutURL}`),
            }
          }
        >
          <User />
          <span>{t('Account')} </span>
        </LI>
        <LI attrLI={{ onClick: Logout }}>
          <LogIn />
          <span>{t('Logout')}</span>
        </LI>
      </UL>
    </li>
  )
}

export default UserHeader
