import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        // we init with resources
        resources: {
            en: {
                translations: {
                    'General': 'General',
                    'Dashboards,Widgets': 'Dashboards,Widgets',
                    'Dashboard': 'Dashboard',
                    'Default': 'Default',
                    'Ecommerce': 'Ecommerce',
                    'Widgets': 'Widgets',
                    'Chart': 'Chart',
                    'Applications': 'Applications',
                    'Ready to use Apps': 'Ready to use Apps'
                }
            },
            cn: {
                translations: {
                    'General': '一般',
                    'Dashboards': '仪表板',
                    'Widgets': '小部件',
                    'Dashboard': '仪表板',
                    'Default': '默认',
                    'Ecommerce': '电子商务',
                    'Chart': '图表',
                    'Applications': '应用领域',
                    'Ready to use Apps': '准备使用的应用程序'
                }
            },
            ae: {
                translations: {
                    'General': 'جنرال لواء',
                    'Dashboards': 'لوحات القيادة والحاجيات',
                    'Widgets': 'لوحات القيادة',
                    'Dashboard': 'لوحة القيادة',
                    'Default': 'إفتراضي',
                    'Ecommerce': 'التجارة الإلكترونية',
                    'Chart': 'مخطط',
                    'Applications': 'التطبيقات',
                    'Ready to use Apps': 'جاهز لاستخدام التطبيقات'
                }
            },
            du: {
                translations: {
                    'Home': 'Heim',
                    'Tickets': 'Tickets',
                    'Concern': 'Meldung',
                    'Pages': 'Seiten',
                    'Hello': 'Hallo',
                    'Search': 'Suchen',
                    'Account': 'Konto',
                    'Username': 'Nutzername',
                    'Password': 'Passwort',
                    'Logout': 'Ausloggen',
                    'Settings': 'Einstellungen',
                    'how can I help you?' : 'wie kann ich dir helfen?',
                    'Welcome to Report Portal' : 'Willkommen im Berichtsportal',
                    'Sign In': 'Anmelden',
                    'Enter your email & password to login': 'Geben Sie Ihre E-Mail-Adresse und Ihr Passwort ein, um sich anzumelden',
                    'Welcome to the Report Portal!': 'Willkommen im Meldungsportal!',
                    'Vacation': 'Urlaub',
                    'Support': 'Unterstützung',
                    'Financial': 'Finanziell',
                    'Please sign in': 'Bitte melden Sie Sich an'
                }
            },
            es: {
                translations: {
                    'Home': 'Hogar',
                    'Tickets': 'Entradas',
                    'Concern': 'Inquietud',
                    'Pages': 'Seiten',
                    'Hello': 'Hallo',
                    'Search': 'Buscar',
                    'Account': 'Cuenta',
                    'Username': 'Nombre de usuario',
                    'Password': 'Contraseña',
                    'Logout': 'Cerrar sesión',
                    'Settings': 'Ajustes',
                    'how can I help you?' : 'wie kann ich dir helfen?',
                    'Welcome to Report Portal' : 'Bienvenido al portal de informes',
                    'Sign In': 'Iniciar sesión',
                    'Enter your email & password to login': 'Ingrese su correo electrónico y contraseña para iniciar sesión',
                    'Welcome to the Report Portal!': 'Willkommen im Meldungsportal!',
                    'Vacation': 'Urlaub',
                    'Support': 'Unterstützung',
                    'Financial': 'Finanziell'
                }
            },
            fr: {
                translations: {
                    'General': 'Générale',
                    'Dashboards,Widgets': 'Tableaux de bord, widgets',
                    'Dashboard': 'Tableau de bord',
                    'Default': 'Défaut',
                    'Ecommerce': 'Commerce électronique',
                    'Widgets': 'Widgets',
                    'Chart': 'Graphique',
                    'Applications': 'Applications',
                    'Ready to use Apps': `Applications prêtes à l'emploi`
                }
            },
            pt: {
                translations: {
                    'General': 'Geral',
                    'Dashboards,Widgets': 'Painéis, Widgets',
                    'Dashboard': 'painel de controle',
                    'Default': 'Padrão',
                    'Ecommerce': 'Comércio eletrônico',
                    'Widgets': 'Widgets',
                    'Chart': 'Gráfico',
                    'Applications': 'Formulários',
                    'Ready to use Apps': 'Aplicativos prontos para usar',
                    'Sign In': 'Aplicativos prontos para usar',
                    'Enter your email & password to login': 'Aplicativos prontos para usar',
                }
            }
        },
        fallbackLng: 'en',
        debug: false,

        // have a common namespace used around the full app
        ns: ['translations'],
        defaultNS: 'translations',

        keySeparator: false, // we use content as keys

        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
