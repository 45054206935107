import axios from 'axios'

export default axios.create({

  baseURL: `${process.env.REACT_APP_INFLOW_BASE_URL}:${process.env.REACT_APP_INFLOW_PORT}/`,
  
  validateStatus: function (status) {
    return status < 500; // Resolve only if the status code is less than 500
  }
})
