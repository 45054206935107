export class ConfigDB {
  static data = {
    settings: {
      layout_type: 'ltr',
      sidebar: {
        type: 'horizontal-wrapper enterprice-type',
        iconType: 'stroke-svg',
      },
      sidebar_setting: 'default-sidebar',
    },
    color: {
      primary_color: '#002878',
      secondary_color: '#23a6f0',
      mix_background_layout: 'light-only',
    },
    router_animation: 'fadeIn',
  }
}
export default ConfigDB
