import React, { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Nav,
  NavItem,
  NavLink,
  Row
} from 'reactstrap'
import { H4 } from '../../../../AbstractElements'
import { useReport } from '../../../../_helper/Reports/ReportProvider'
import Chat from '../../../Chat'
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

const AssetDetails = () => {
  const { t } = useTranslation()
  const navigate = useNavigate();
  const { id : id} = useParams();
  const { reportLists } = useReport();
  const [messageInput, setMessageInput] = useState('')

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const assetDetails = reportLists.filter((i)=> i._id === id ).map((item)=>{ return(item) });
  const customizeEntries = assetDetails[0]?.customizeEntries || [];
  const groupedArray = assetDetails[0]?.properties.map((properties) => {
    const options = { option: customizeEntries.filter(customizeEntry => properties.customizeType === customizeEntry._id) };
    return { ...properties, options };
  }) || [];

  const handleGoBack = () => {
    navigate(-1)
  }

  const handleMessageChange = (message) => {
    setMessageInput(message)
  }


  console.log(assetDetails)

  return (
    <>
      <Container fluid={true}>
        <Row style={{ marginTop: 30 }}>
          <Col sm="12" md="10" className="offset-md-1">
            <Row style={{ marginTop: 30 }}>
              <Col sm="12">
                <Card>
                  <CardBody>
                    <Nav className="nav-pills">
                      <NavItem>
                        <NavLink
                          href="#open-task"
                          className="active"
                          onClick={handleGoBack}
                        >
                          <i className="fa fa-chevron-left"></i> Back
                        </NavLink>
                      </NavItem>
                    </Nav>

                    {/* <Card className="shadow-none">
                      <CardHeader>
                        <H4>Urlaubsantrag <span style={{background: '#c2f1c8', padding: '5px 15px 5px 15px',marginLeft: 10, borderRadius: 5}}>75 %</span></H4>
                      </CardHeader>
                      <CardBody>
                        <div className="row">
                          <div
                            className="col-md-8"
                            style={{
                              borderBottom: '1px solid white',
                              paddingRight: 50,
                            }}
                          >
                            <Form className="needs-validation" noValidate="">
                              <Row>
                                <Col md="6">
                                  <div className="form-floating mb-3">
                                    <input
                                      className="form-control"
                                      id="validationCustom01"
                                      type="date"
                                    />
                                    <label htmlFor="floatingInput22">Von</label>
                                  </div>
                                </Col>
                                <Col md="6">
                                  <div className="form-floating mb-3">
                                    <input
                                      className="form-control"
                                      id="validationCustom01"
                                      type="date"
                                    />
                                    <label htmlFor="floatingInput22">Bis</label>
                                  </div>
                                </Col>
                                <Col md="6">
                                  <div className="form-floating mb-3">
                                    <input
                                      className="form-control"
                                      id="validationCustom01"
                                      type="number"
                                    />
                                    <label htmlFor="floatingInput22">
                                      Description
                                    </label>
                                  </div>
                                </Col>
                                <Col md="6">
                                  <div className="form-floating mb-3">
                                    <input
                                      className="form-control"
                                      id="validationCustom01"
                                      type="text"
                                    />
                                    <label htmlFor="floatingInput22">
                                      Description
                                    </label>
                                  </div>
                                </Col>
                                <Col md="12">
                                  <div className="form-floating mb-3">
                                    <textarea
                                      className="form-control"
                                      style={{ height: 80 }}
                                    ></textarea>
                                    <label htmlFor="floatingInput22">
                                      Description
                                    </label>
                                  </div>
                                </Col>
                              </Row>
                            </Form>

                            <Link
                              to={`${process.env.PUBLIC_URL}/pages/task-details`}
                            >
                              <div className="row m-1">
                                <div
                                  className="col-md-6 p-2"
                                  style={{
                                    background: '#dceaf7',
                                    borderBottom: '1px solid white',
                                  }}
                                >
                                  Task 1
                                </div>
                                <div
                                  className="col-md-2 p-2"
                                  style={{
                                    background: '#dceaf7',
                                    borderBottom: '1px solid white',
                                  }}
                                >
                                  finished (100%)
                                </div>
                                <div
                                  className="col-md-2 p-2"
                                  style={{
                                    background: '#dceaf7',
                                    borderBottom: '1px solid white',
                                  }}
                                >
                                  3/3
                                </div>
                                <div
                                  className="col-md-2 p-2"
                                  style={{
                                    background: '#dceaf7',
                                    borderBottom: '1px solid white',
                                  }}
                                >
                                  <i
                                    class="fa fa-wechat"
                                    style={{ fontSize: 28 }}
                                  ></i>{' '}
                                  4
                                </div>
                              </div>
                            </Link>

                            <Link
                              to={`${process.env.PUBLIC_URL}/pages/task-details`}
                              style={{borderRadius: 10}}
                            >
                              <div className="row m-1" >
                                <div
                                  className="col-md-6 p-2"
                                  style={{
                                    background: '#dceaf7',
                                    borderBottom: '1px solid white',
                                  }}
                                >
                                  Task 1
                                </div>
                                <div
                                  className="col-md-2 p-2"
                                  style={{
                                    background: '#dceaf7',
                                    borderBottom: '1px solid white',
                                  }}
                                >
                                  In Progress (50%)
                                </div>
                                <div
                                  className="col-md-2 p-2"
                                  style={{
                                    background: '#dceaf7',
                                    borderBottom: '1px solid white',
                                  }}
                                >
                                  0/3
                                </div>
                                <div
                                  className="col-md-2 p-2"
                                  style={{
                                    background: '#dceaf7',
                                    borderBottom: '1px solid white',
                                  }}
                                >
                                  <i
                                    class="fa fa-wechat"
                                    style={{ fontSize: 28 }}
                                  ></i>{' '}
                                  3
                                </div>
                              </div>
                            </Link>
                          </div>
                          <div
                            className="col-md-4 p-0"
                            style={{
                              background: '#ecf0f1',
                              borderRadius: 5,
                            }}
                          >
                           <Chat />
                          </div>
                        </div>
                      </CardBody>
                    </Card> */}

                  <Card>
                    <CardHeader><H4>{assetDetails[0].name}</H4></CardHeader>
                    <CardBody>
                      <Form className='needs-validation' noValidate=''>
                        <Row>
                          {
                            groupedArray.map((form,index)=>(
                              <Col sm="6" key={index}>
                                <div className="form-floating mb-3">
                                  {
                                    form.mode === 2 ? <input className="form-control" id='validationCustom01' type='number' name={`Name${index}`} {...register(`Name${index}`, { required: form.mandatory === true ? true : false })} /> 
                                    : form.mode === 3 ? <input className="form-control" id='validationCustom01' type='date' name={`Name${index}`} {...register(`Name${index}`, { required: form.mandatory === true ? true : false })} /> 
                                    : form.mode === 4 && form.customizeType === "TEXT" ? <input className="form-control" id='validationCustom01' type='text' name={`Name${index}`} {...register(`Name${index}`, { required: form.mandatory === true ? true : false })} /> 
                                    : form.mode === 4 && form.customizeType === "TEXT2" ? <textarea className="form-control" style={{height:80}} name={`Name${index}`} {...register(`Name${index}`, { required: form.mandatory === true ? true : false })}></textarea> 
                                    : form.mode === 7 ? 
                                    <select
                                      className="form-select form-control form-select"
                                      id={`Name${index}`}
                                      name={`Name${index}`}
                                      {...register(`Name${index}`, { required: form.mandatory })}
                                      defaultValue=""
                                  >
                                      <option value=''></option>
                                      {form.options.option[0].predefiniedProperties.map((property, i) => (
                                        <option key={i} value={property.value}>
                                          {property.value}
                                        </option>
                                      ))}
                                  </select> 
                                    : <input className="form-control" id='validationCustom01' type='text' name={`Name${index}`} {...register(`Name${index}`, { required: form.mandatory === true ? true : false })} /> 
                                  }
                                  <label htmlFor="floatingInput22">{form.mandatory === true&&<span style={{color:'#F07B05'}}>*</span>}{form.name}</label>
                                  <span className='d-block' style={{color:'#F07B05'}}>{errors[`Name${index}`] && 'This field is required'}</span>
                                </div>
                              </Col>
                            ))
                          }
                          
                          <Col md='12'>
                            <div className="form-floating mb-3">
                              <textarea className="form-control" style={{height:80}} {...register('description')}></textarea>
                              <label htmlFor="floatingInput22">Description</label>
                            </div>
                          </Col>
    
                        </Row>
                      </Form>
                    </CardBody>
                  </Card>

                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default AssetDetails
