import React, { useState } from 'react';

const CustomPagination = ({ totalItems, itemsPerPage, onPageChange, onItemsPerPageChange, startIndex, endIndex }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handleItemsPerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value);
    onItemsPerPageChange(newItemsPerPage);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    onPageChange(page);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
          <button className="page-link" onClick={() => handlePageChange(i)}>{i}</button>
        </li>
      );
    }
    return pageNumbers;
  };

  return (
    <div>
      <nav aria-label="Page navigation" className='mt-4'>
        <ul className="pagination justify-content-end align-items-center">
          <li className="page-item">
            <select className="form-select form-select-sm" onChange={handleItemsPerPageChange} value={itemsPerPage}>
              <option value="5">5 per page</option>
              <option value="10">10 per page</option>
              <option value="20">20 per page</option>
              <option value="50">50 per page</option>
              <option value="100">100 per page</option>
            </select>
          </li>
          <p className="for-light text-center mt-3 p-3">{startIndex}-{endIndex} of {totalItems}</p>
          <p className="for-dark text-white text-center mt-3 p-3">{startIndex}-{endIndex} of {totalItems}</p>
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <button className="page-link rounded-circle d-flex justify-content-center align-items-center m-1 custom-btn" onClick={() => handlePageChange(1)} disabled={currentPage === 1}>
                <i style={{fontSize: 20}} className={`fa fa-backward`}></i>
            </button>
          </li>
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <button className="page-link rounded-circle d-flex justify-content-center align-items-center m-1 custom-btn" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                <i style={{fontSize: 20}} className={`fa fa-chevron-left`}></i>
            </button>
          </li>
          {renderPageNumbers()}
          <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
            <button className="page-link rounded-circle d-flex justify-content-center align-items-center m-1 custom-btn" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                <i style={{fontSize: 20}} className={`fa fa-chevron-right`}></i>
            </button>
          </li>
          <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
            <button className="page-link rounded-circle d-flex justify-content-center align-items-center custom-btn" onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages}>
                <i style={{fontSize: 20}} className={`fa fa-forward`}></i>
            </button>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default CustomPagination;
