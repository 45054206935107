import axios from 'axios';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { Btn, H4, P } from '../AbstractElements';
import { useAuth } from '../_helper/Auth/AuthProvider';
import login_img from '../assets/images/login/login_bg_laptop.jpg';
import login_logo from '../assets/images/logo/inpro-group-logo.png';

const Signin = () => {
  const { t } = useTranslation();
  const { getUserInfo, login } = useAuth();
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()
  const [showPassword, setShowPassword] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const history = useNavigate();

  const loginAuth = async (e) => {
    e.preventDefault();
    setIsLoggingIn(true);

    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    const backendPort = process.env.REACT_APP_BACKEND_PORT;

    let apiUrl = `${backendUrl}`;

    if (backendPort) {
      apiUrl += `:${backendPort}`;
    }

    try {
      const response = await axios.post(apiUrl += '/api/login', {
        email,
        password,
      });

      const token = response.data.token;
      localStorage.setItem('login', JSON.stringify(true));
      login(token);
      history(`${process.env.PUBLIC_URL}/pages/home`);
      toast.success('Successfully logged in!..', { autoClose: 1000 });
      setIsLoggingIn(false);
      getUserInfo(token);
    } catch (error) {
      console.log('ERR', error);
      setIsError(true);
      setIsLoggingIn(false);
      if (error.response && error.response.status === 401) {
        toast.error(error.response.data.error, { autoClose: 1000 });
      }
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div style={{ height: '100vh', padding: '0', overflow: 'hidden' }}>
      {isError && <ToastContainer autoClose={1000} />}
      <Container fluid className="h-100 p-0">
        <Row className="h-100 g-0">
          <Col
            md={4}
            className="h-100 d-flex justify-content-center align-items-center"
          >
            <div
              style={{
                backgroundColor: '#fff',
                padding: '20px',
                width: '100%',
                maxWidth: '400px',
              }}
            >
              <div className="login-logo" style={{ marginBottom: 0 }}>
                <img
                  src={login_logo}
                  alt="Logo"
                  style={{
                    marginBottom: '20px',
                    maxHeight: '90px',
                    maxWidth: '100%',
                    width: 'auto',
                  }}
                />
              </div>

              <div>
                <h2 style={{ color: "#F07B05", paddingBottom: 50, paddingTop: 30, }}>Meldeportal</h2>
              </div>

              <Form className="theme-form">
                <h5>{t('Please sign in')}</h5>
                <FormGroup>
                  <Label className="col-form-label">{t('Username')}</Label>
                  <Input
                    className="form-control"
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                </FormGroup>
                <FormGroup className="position-relative">
                  <Label className="col-form-label">{t('Password')}</Label>
                  <div className="position-relative">
                    <Input
                      className="form-control"
                      type={showPassword ? 'text' : 'password'}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <button
                      type="button"
                      className="toggle-password-btn"
                      onClick={togglePasswordVisibility}
                      style={{
                        position: 'absolute',
                        right: '10px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        border: 'none',
                        background: 'none',
                      }}
                    >
                      {showPassword ? (
                        <i className="fa fa-eye-slash" style={{ color: 'gray' }} />
                      ) : (
                        <i className="fa fa-eye" style={{ color: 'gray' }} />
                      )}
                    </button>
                  </div>
                </FormGroup>
                <div className="position-relative form-group mb-0">
                  <Btn
                    attrBtn={{
                      color: 'primary',
                      className: 'd-block w-100 mt-4',
                      onClick: (e) => loginAuth(e),
                    }}
                  >
                    {isLoggingIn ? 'Logging in...' : 'SignIn'}
                  </Btn>
                </div>
              </Form>
            </div>
          </Col>

          {/* Banner Column */}
          <Col md={8} className="h-100">
            <img
              src={login_img}
              alt="login page img"
              className="w-100 h-100 object-fit-cover"
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Signin;