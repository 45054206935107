import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Row,
  Table,
} from 'reactstrap'
import { UL, LI } from '../../AbstractElements'

export const HomeSkeleton = () => {
  return (
    <Row>
      <Col sm="6">
        <Card className="p-10">
          <CardHeader className="pt-3 pb-1">
            <Skeleton
              count={1}
              height={30}
              width={'30%'}
              style={{ borderRadius: 10, marginBottom: 10 }}
            />
          </CardHeader>
          <CardBody
            className="p-0 custom-scrollbar rounded"
            style={{ maxHeight: 300, overflow: 'auto' }}
          >
            <div className="taskadd">
              <div className="table-responsive table-hover p-0">
                <Table hover={true}>
                  <thead>
                    <tr></tr>
                  </thead>
                  <tbody>
                    {[...Array(3)].map((item, index) => {
                      return (
                        <tr key={index}>
                          <td style={{ width: '30%' }}>
                            <Skeleton
                              count={1}
                              height={30}
                              style={{ borderRadius: 10, marginBottom: 10 }}
                            />
                          </td>
                          <td>
                            <Skeleton
                              count={1}
                              height={30}
                              style={{ borderRadius: 10, marginBottom: 10 }}
                            />
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col sm="6">
        <Card className="p-10">
          <CardHeader className="pt-3 pb-1">
            <Skeleton
              count={1}
              height={30}
              width={'30%'}
              style={{ borderRadius: 10, marginBottom: 10 }}
            />
          </CardHeader>
          <CardBody
            className="p-0 custom-scrollbar rounded"
            style={{ maxHeight: 300, overflow: 'auto' }}
          >
            <div className="taskadd">
              <div className="table-responsive table-hover p-0">
                <Table hover={true}>
                  <thead>
                    <tr></tr>
                  </thead>
                  <tbody>
                    {[...Array(3)].map((item, index) => {
                      return (
                        <tr key={index}>
                          <td style={{ width: '30%' }}>
                            <Skeleton
                              count={1}
                              height={30}
                              style={{ borderRadius: 10, marginBottom: 10 }}
                            />
                          </td>
                          <td>
                            <Skeleton
                              count={1}
                              height={30}
                              style={{ borderRadius: 10, marginBottom: 10 }}
                            />
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export const ConcernSkeleton = () => {
  return (
    <Card>
      <CardHeader>
        <Col sm="12">
          <Skeleton
            count={1}
            height={30}
            width={'30%'}
            style={{ borderRadius: 10, marginBottom: 10 }}
          />
        </Col>
      </CardHeader>
      <CardBody>
        <Row>
          <Col sm="6">
            <Skeleton
              count={3}
              height={58}
              style={{ borderRadius: 10, marginBottom: 10 }}
            />
          </Col>
          <Col sm="6">
            <Skeleton
              count={3}
              height={58}
              style={{ borderRadius: 10, marginBottom: 10 }}
            />
          </Col>
          <Col sm="12">
            <Skeleton
              count={1}
              height={80}
              style={{ borderRadius: 10, marginBottom: 10 }}
            />
          </Col>
        </Row>
      </CardBody>
      <CardFooter>
        <Skeleton
          count={1}
          height={35}
          width={'8%'}
          style={{ borderRadius: 10 }}
        />
      </CardFooter>
    </Card>
  )
}

export const TicketSkeleton = () => {
  return (
    <Table className="m-t-30">
      <tbody className="text-center">
        {[...Array(5)].map((item) => (
          <tr>
            <th scope="row">
              <Skeleton
                count={1}
                height={35}
                width={'100%'}
                style={{ borderRadius: 10 }}
              />
            </th>
            <td>
              <Skeleton
                count={1}
                height={35}
                width={'100%'}
                style={{ borderRadius: 10 }}
              />
            </td>
            <td>
              <Skeleton
                count={1}
                height={35}
                width={'100%'}
                style={{ borderRadius: 10 }}
              />
            </td>
            <td>
              <Skeleton
                count={1}
                height={35}
                width={'100%'}
                style={{ borderRadius: 10 }}
              />
            </td>
            <td>
              <Skeleton
                count={1}
                height={35}
                width={'100%'}
                style={{ borderRadius: 10 }}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

export const FormSkeleton = () => {
  return (
    <Row>
      {[...Array(5)].map((item) => (
        <Col sm="6">
          <Skeleton
            count={1}
            height={58}
            width={'100%'}
            style={{ borderRadius: 10, marginBottom: 10 }}
          />
        </Col>
      ))}
    </Row>
  )
}

export const ChecklistSkeleton = () => {
  return (
    <UL>
      {[...Array(3)].map((item) => (
        <LI
          attrLI={{
            className: 'clearfix border-0 p-0',
          }}
        >
          <Skeleton
            count={1}
            height={40}
            width={'100%'}
            style={{ borderRadius: 10, marginBottom: 5 }}
          />
        </LI>
      ))}
    </UL>
  )
}

export const ChatSkeleton = () => {
  return (
    <UL>
      <tbody className="text-center">
        <LI
          attrLI={{
            className: 'clearfix border-0 mb-2',
          }}
        >
          <div
            style={{
              borderRadius: 10,
              minWidth: 350,
              display: 'inline-block', // Ensures the width adjusts automatically
              position: 'relative',
            }}
          >
            <Skeleton
              count={1}
              height={100}
              width={'100%'}
              style={{ borderRadius: 10 }}
            />
          </div>
        </LI>
        <LI
          attrLI={{
            className: 'clearfix border-0 mb-2',
          }}
        >
          <div
            style={{
              borderRadius: 10,
              minWidth: 350,
              display: 'inline-block', // Ensures the width adjusts automatically
              position: 'relative',
            }}
          >
            <Skeleton
              count={1}
              height={100}
              width={'100%'}
              style={{ borderRadius: 10 }}
            />
          </div>
        </LI>
      </tbody>
    </UL>
  )
}
